function randInt(max: number) {
    // FIXME: we've dropped IE10 support, so we should use crypto.getRandomBytes().
    // Math.random shouldn't be used to generate ids.
    return Math.floor(max * Math.random());
}

export function sessionId() {
    const alphabet = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const length = 12;
    let id = '';
    let i = 0;
    for (; i < length; i++) {
        id += alphabet.charAt(randInt(alphabet.length));
    }

    return id;
}
