export function parseDocumentDataFromUrl(url: string) {
    const result = url.match(
        /^https?:\/\/[^\/]*issuu.com\/([^\/]+)\/docs\/([^\/\?#]+)(?:\/([0-9]+))?/,
    );
    if (result) {
        return {
            username: result[1],
            docname: result[2],
            pageNumber: parseInt(result[3], 10) || 1,
        };
    }

    return undefined;
}
