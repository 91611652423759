export type UrlObject = {
    baseurl: string;
    query: {
        identifier: string;
        embedType: string;
        u?: string;
        d?: string;
        p?: number;
    };
    hash?: string;
};

export function urlObjectToUrl(urlObject: UrlObject) {
    let queryStr = '';
    if (typeof urlObject.query === 'object') {
        const query = urlObject.query;
        const queryList: string[] = [];
        for (const [key, value] of Object.entries(query)) {
            if (value === '') {
                queryList.push(key);
            } else if (typeof value === 'string') {
                queryList.push(`${key}=${encodeURIComponent(value)}`);
            } else if (typeof value !== 'undefined') {
                queryList.push(`${key}=${value.toString()}`);
            }
        }
        if (queryList.length) {
            queryStr = `?${queryList.join('&')}`;
        }
    }
    const hashStr = !urlObject.hash ? '' : `#${urlObject.hash}`;

    return `https://${urlObject.baseurl}${queryStr}${hashStr}`;
}
