import { captureException, captureMessage } from '@sentry/browser';

export function info(message: string) {
    captureMessage(message);
}

export function error(e: Error) {
    captureException(e);
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function ignore(_e: Error) {}
