import { IgnoredError } from '@issuu/widget-reader3/src/utilities/sentry-ignored-errors';
import type { Options } from '@sentry/types';

type Arguments = {
    dsn: string;
    environment: 'dev' | 'prod';
    isDeployed: boolean;
    release: string;
};

const eventLimiter: { [msg: string]: boolean } = {};

export default ({ dsn, environment, isDeployed, release }: Arguments): Options => ({
    dsn,
    environment,
    release,
    sampleRate: isDeployed ? 0.5 : 1, // Handle all exceptions, when running in dev mode
    beforeSend(event, hint) {
        if (isDeployed) {
            const stacktraceFrames = (event.exception?.values || [])[0]?.stacktrace?.frames || [];
            // Ignoring errors from <anonymous> files.
            // Adapted from <https://github.com/getsentry/sentry-javascript/issues/3147#issuecomment-768978043>.
            // Those are errors from injected code, which we have no control of
            const firstFilename = stacktraceFrames[0]?.filename;
            if (firstFilename === '<anonymous>') {
                return null;
            }

            // Limit events to 1 per minute.
            // Adapted from <https://github.com/getsentry/sentry-javascript/issues/435#issuecomment-605634518>.
            const originalException = hint?.originalException;
            const msg =
                typeof originalException === 'string'
                    ? originalException
                    : originalException instanceof Error
                      ? originalException.message
                      : 'unknown error';

            if (Object.values(IgnoredError).includes(msg as IgnoredError)) {
                console.warn('[Sentry] Ignored error', msg);

                return null;
            }

            // Ignore certain errors from "/uc.js". The errors can be triggered
            // by any 3rd party scripts and there is nothing to fix.
            // 1. where it cannot handle MessageEvents without valid "source"
            //    property.
            // 2. Where it tries to set window.CookieConsent.iframeReady and
            //    failing. Probably by the time it is called
            //    window.CookieConsent no longer exists.
            const lastFilename = stacktraceFrames[stacktraceFrames.length - 1]?.filename;
            if (
                lastFilename?.startsWith('https://consent.cookiebot.com/') &&
                (msg.includes('postMessage') || msg.includes('iframeReady'))
            ) {
                return null;
            }

            // do not send event if already sent in last 1 minute
            if (msg in eventLimiter) {
                console.error('[Sentry] Rate limiting activated for', msg);

                return null;
            }

            eventLimiter[msg] = true;

            setTimeout(() => {
                delete eventLimiter[msg];
            }, 60000);

            return event;
        }

        return null;
    },
});
