import * as logger from './logger';

function getEmbedIdApiUrl(embedId: string) {
    return `https://${__SCRIPT_EMBED__EMBED_DNS__}/config/${embedId.split('/')[1]}.json`;
}

export function getEmbedConfig(
    embedId: string,
    onSuccess: (config: { enablePoweredByIssuu: boolean }) => void,
    onError: () => void,
) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', getEmbedIdApiUrl(embedId), true);
    xhr.onreadystatechange = function () {
        try {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (xhr.status === 200) {
                    const config = JSON.parse(xhr.responseText) as unknown;

                    if (isCorrectType(config)) {
                        onSuccess(config);
                    } else {
                        onError();
                    }
                } else {
                    onError();
                }
            }
        } catch (e) {
            if (e instanceof Error) {
                logger.error(e);
            }
        }
    };
    xhr.send();
}

function isCorrectType(data: unknown): data is { enablePoweredByIssuu: boolean } {
    return typeof data === 'object' && data !== null && data.hasOwnProperty('enablePoweredByIssuu');
}
