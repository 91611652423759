export enum IgnoredError {
    DocumentNotLoad = 'The document could not be loaded.',
    LicensingNotLoaded = 'Licenses failed loading',
    ClippingNotLoad = 'The Clippings could not be loaded.',
    DocumentIdMissing = 'documentId is not found!',
    JsonNotLoad = 'XHR could not load JSON',
    PreviewDisableLicenseCheck = 'Disable license check in Publisher Preview mode',
    PaywallPreviewDisableLicenseCheck = 'Disable license check in paywallPreview mode',
    SmartzoomNotLoaded = 'Smartzoom failed to load',
}
