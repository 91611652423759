import { getEmbedConfig } from './api';

function iframe(src: string) {
    return (
        `<iframe src="${src}" style="border:none;width:100%;height:100%;" ` +
        'title="Document loading..." ' +
        'sandbox="allow-top-navigation allow-top-navigation-by-user-activation ' +
        'allow-downloads allow-scripts allow-same-origin allow-popups allow-forms" allowfullscreen ' +
        'webkitallowfullscreen mozallowfullscreen msallowfullscreen allow="clipboard-write">' +
        '</iframe>'
    );
}

export function loadEmbedMarkup(
    iframeSrc: string,
    _embedId: string | undefined,
    callback: (value: string) => void,
) {
    // If it's not loaded by robot, never serve the Issuu branding links on the host page.
    // Let the iframe does its job
    callback(iframe(iframeSrc));
}

function iframeForBot(iframeSrc: string) {
    return `
        <div style="width:100%;height:100%;display:flex;flex-direction:column;align-items:stretch;">
            ${iframe(iframeSrc)}
            <div style="display:flex; height:30px; justify-content: space-between; align-items: center">
                <div>
                    <span>Powered by </span>
                    <span><a href="https://${__SCRIPT_EMBED__MAIN_DNS__}/explore" target="_blank">Issuu</a></span>
                </div>
                <span><a href="https://${__SCRIPT_EMBED__MAIN_DNS__}/tryissuu" target="_blank">Publish for Free</a></span>
            </div>
        </div>
    `;
}

export function loadRobotEmbedMarkup(
    iframeSrc: string,
    embedId: string | undefined,
    callback: (value: string) => void,
) {
    if (embedId) {
        getEmbedConfig(
            embedId,
            function success(config) {
                if (config.enablePoweredByIssuu) {
                    callback(iframeForBot(iframeSrc));
                } else {
                    loadEmbedMarkup(iframeSrc, embedId, callback);
                }
            },
            function error() {
                callback(iframeForBot(iframeSrc));
            },
        );
    } else {
        // There is no embed config, always serve the links
        callback(iframeForBot(iframeSrc));
    }
}
