import type { ReaderApi } from '@issuu/widget-reader3/src/reader/types';

import * as logger from './logger';

type Status = {
    pageCount: number;
    pageNumber: number;
    title: string;
    prevEvent?: MessageEvent;
    onPageChangedListeners: Array<(pageNumber: number) => void>;
    onReaderLoaded(): void;
};

function postMessageToEmbed(status: Status, message: { type: string; pageNumber?: number }) {
    try {
        const { prevEvent } = status;
        if (prevEvent) {
            (prevEvent.source as Window).postMessage(message, prevEvent.origin);
        }
    } catch (e) {
        if (e instanceof Error) {
            logger.error(e);
        }
    }
}

function receiveMessageFromEmbed(identifier: string, status: Status) {
    if (!window.addEventListener) {
        // Legacy browsers
        return;
    }
    window.addEventListener(
        'message',
        event => {
            try {
                if (event.origin.indexOf(__SCRIPT_EMBED__EMBED_DNS__) === -1) {
                    return;
                }
                if (!event.data || event.data.identifier !== identifier) {
                    return;
                }
                status.prevEvent = event;
                switch (event.data.type) {
                    case 'issuu.documentLoaded':
                        status.pageCount = event.data.pageCount;
                        status.pageNumber = event.data.pageNumbers[0];
                        status.title = event.data.title;
                        status.onReaderLoaded();
                        break;
                    case 'issuu.pageChanged':
                        status.pageNumber = event.data.pageNumbers[0];
                        status.onPageChangedListeners.forEach(listener => {
                            try {
                                listener(status.pageNumber);
                            } catch (e) {
                                if (e instanceof Error) {
                                    logger.ignore(e);
                                }
                            }
                        });
                        break;
                    default:
                        break;
                }
            } catch (e) {
                if (e instanceof Error) {
                    logger.error(e);
                }
            }
        },
        true,
    );
}

export type ReaderObject = {
    getReaderApi: () => Partial<ReaderApi> & {
        addEventListener(
            type: string,
            listener: ((pageNumber: number) => void) | keyof Window,
        ): void;
    };
    getEmbedId: () => string | undefined;
    isLoaded: () => boolean;
};

export function createReaderObject(
    identifier: string,
    embedId: string | undefined,
    onReaderLoaded: () => void,
): ReaderObject {
    const status: Status = {
        pageCount: 0,
        pageNumber: 1,
        title: '',
        prevEvent: undefined,
        onReaderLoaded,
        onPageChangedListeners: [],
    };

    receiveMessageFromEmbed(identifier, status);

    return {
        getReaderApi: () => ({
            addEventListener: (type, listener) => {
                try {
                    if (type === 'change') {
                        if (
                            typeof listener === 'string' &&
                            typeof window[listener] === 'function'
                        ) {
                            status.onPageChangedListeners.push(window[listener]);
                        } else if (typeof listener === 'function') {
                            status.onPageChangedListeners.push(listener);
                        }
                    }
                } catch (e) {
                    if (e instanceof Error) {
                        logger.error(e);
                    }
                }
            },
            getPageNumber: () => status.pageNumber,
            setPageNumber: value => {
                postMessageToEmbed(status, {
                    type: 'issuu.setPageNumber',
                    pageNumber: typeof value === 'string' ? parseInt(value, 10) : value,
                });
            },
            getPageCount: () => status.pageCount,
            getTitle: () => status.title,
            goToPreviousPage: () => {
                postMessageToEmbed(status, {
                    type: 'issuu.goToPreviousPage',
                });
            },
            goToNextPage: () => {
                postMessageToEmbed(status, {
                    type: 'issuu.goToNextPage',
                });
            },
            goToFirstPage: () => {
                postMessageToEmbed(status, {
                    type: 'issuu.goToFirstPage',
                });
            },
            goToLastPage: () => {
                postMessageToEmbed(status, {
                    type: 'issuu.goToLastPage',
                });
            },
        }),
        getEmbedId: () => embedId,
        isLoaded: () => !!status.pageCount,
    };
}
