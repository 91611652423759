const EMBED_DEFAULT_CLASS = '.issuuembed';
const EMBED_RENDERED_CLASS = 'issuu-isrendered';
const ATTRIBUTE_CONFIG_ID = 'data-configid';
const ATTRIBUTE_URL = 'data-url';

export function getAllElements() {
    return document.querySelectorAll(EMBED_DEFAULT_CLASS);
}

export function getNotRenderedElements() {
    const listElements = getAllElements();
    const list: Element[] = [];
    for (let index = 0; index < listElements.length; index++) {
        const elm = listElements[index];
        if (elm.className.indexOf(EMBED_RENDERED_CLASS) === -1) {
            list.push(elm);
        }
    }

    return list;
}

export function setElementToRendered(elm: Element) {
    elm.className += ` ${EMBED_RENDERED_CLASS}`;
}

export function getUrlAttribute(elm: Element) {
    return elm.getAttribute(ATTRIBUTE_URL);
}

export function getConfigIdAttribute(elm: Element) {
    return elm.getAttribute(ATTRIBUTE_CONFIG_ID);
}
