import * as Sentry from '@sentry/browser';

import sentryConfig from '../universal/util/sentry-config';

export default function initSentry() {
    Sentry.setTag('endpoint', 'script-embed');
    Sentry.init({
        ...sentryConfig({
            dsn: __SCRIPT_EMBED__SENTRY_DSN__,
            environment: __SCRIPT_EMBED__ENVIRONMENT__,
            isDeployed: !__DEV__,
            release: `frontend-reader3@${__SCRIPT_EMBED__GIT_COMMIT_SHORT__}`,
        }),
        defaultIntegrations: false,
    });
}
