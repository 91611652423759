import type { ReaderApi } from '@issuu/widget-reader3/src/reader/types';

import { parseDocumentDataFromUrl } from './document-url-parser';
import {
    getConfigIdAttribute,
    getNotRenderedElements,
    getUrlAttribute,
    setElementToRendered,
} from './dom';
import * as logger from './logger';
import { loadEmbedMarkup, loadRobotEmbedMarkup } from './markup';
import type { ReaderObject } from './reader-object';
import { createReaderObject } from './reader-object';
import initSentry from './sentry-config';
import { sessionId } from './session-id';
import type { UrlObject } from './url';
import { urlObjectToUrl } from './url';

function isRobotUserAgent(agent: string) {
    const CRAWLER_REGEX =
        /^(?:Mediapartners-Google|Yahoo|MSNPTC|Baiduspider|Yandex|proximic|Ezooms)|bot\/|Yahoo! Slurp|Ask Jeeves\/Teoma|spider|crawl|ia_archiver|heritrix\//i;

    return CRAWLER_REGEX.test(agent);
}

declare global {
    const __SCRIPT_EMBED__GIT_COMMIT_SHORT__: string;
    const __SCRIPT_EMBED__SENTRY_DSN__: string;
    const __SCRIPT_EMBED__EMBED_DNS__: string;
    const __SCRIPT_EMBED__MAIN_DNS__: string;
    const __SCRIPT_EMBED__ENVIRONMENT__: 'prod' | 'dev';
    interface Window {
        IssuuReaders: {
            loaded: boolean;
            get(id: string): ReaderApi;
            add(): void;
        };
        onIssuuReadersLoaded?: () => void;
    }
}

export default function init(global: Window) {
    const _isRobot = isRobotUserAgent(window.navigator.userAgent);
    const embedsArray: ReaderObject[] = [];

    function onReaderLoaded(element: Element) {
        return () => {
            for (let i = 0; i < embedsArray.length; i++) {
                if (!embedsArray[i].isLoaded()) {
                    return;
                }
                const embedApi = embedsArray[i].getReaderApi();
                if (embedApi.getTitle) {
                    const docTitle = embedApi.getTitle();
                    const iframe = element.querySelector('iframe');
                    iframe?.setAttribute('title', `Document: "${docTitle}" loaded from Issuu.com`);
                }
            }

            // All of the embeds are loaded, call window.onIssuuReadersLoaded if we haven't done that before
            if (!global.IssuuReaders.loaded) {
                global.IssuuReaders.loaded = true;
                if (typeof window.onIssuuReadersLoaded === 'function') {
                    try {
                        window.onIssuuReadersLoaded();
                    } catch (e) {
                        if (e instanceof Error) {
                            logger.ignore(e);
                        }
                    }
                    // TODO: find out whether users expect parameters in onIssuuReadersLoaded
                    // logger.info('Usage of reader js api');
                }
            }
        };
    }

    function renderIframeEmbed(
        identifier: string,
        embedId: string | undefined,
        element: Element,
        urlObject: UrlObject,
    ) {
        embedsArray.push(createReaderObject(identifier, embedId, onReaderLoaded(element)));
        const markupFn = _isRobot ? loadRobotEmbedMarkup : loadEmbedMarkup;
        markupFn(urlObjectToUrl(urlObject), embedId, embedMarkup => {
            element.innerHTML = embedMarkup;
        });
    }

    function renderIframeEmbedWithUrl(
        identifier: string,
        element: Element,
        urlObject: UrlObject,
        attrUrl: string,
    ) {
        const documentData = parseDocumentDataFromUrl(attrUrl);
        if (documentData) {
            urlObject.query.u = documentData.username;
            urlObject.query.d = documentData.docname;
            urlObject.query.p = documentData.pageNumber;
        }
        renderIframeEmbed(identifier, undefined, element, urlObject);
    }

    function render(element: Element) {
        const identifier = sessionId();

        const urlObject: UrlObject = {
            baseurl: `${__SCRIPT_EMBED__EMBED_DNS__}/embed.html`,
            query: { identifier, embedType: 'script' },
        };

        setElementToRendered(element);

        const attrConfigId = getConfigIdAttribute(element);
        if (attrConfigId) {
            urlObject.hash = attrConfigId;

            return renderIframeEmbed(identifier, attrConfigId, element, urlObject);
        }

        const attrUrl = getUrlAttribute(element);
        if (attrUrl) {
            return renderIframeEmbedWithUrl(identifier, element, urlObject, attrUrl);
        }

        return renderIframeEmbed(identifier, undefined, element, urlObject);
    }

    function get(embedId: string) {
        let readerObj = {};
        try {
            for (let i = 0; i < embedsArray.length; i++) {
                if (embedsArray[i].getEmbedId() === embedId) {
                    readerObj = embedsArray[i].getReaderApi();
                    break;
                }
            }
        } catch (e) {
            if (e instanceof Error) {
                logger.error(e);
            }
        }

        return readerObj;
    }

    function add() {
        try {
            const list = getNotRenderedElements();
            for (let i = 0; i < list.length; i++) {
                render(list[i]);
            }
        } catch (e) {
            if (e instanceof Error) {
                logger.error(e);
            }
        }
    }

    function createGlobalObject() {
        window.addEventListener('DOMContentLoaded', () => {
            global.IssuuReaders.add();
        });

        // In the case of Rocketscript the DOMContentLoaded is not called
        window.addEventListener('load', () => {
            global.IssuuReaders.add();
        });

        return {
            loaded: false,
            get,
            add,
        };
    }

    initSentry();

    global.IssuuReaders = global.IssuuReaders || createGlobalObject();
    global.IssuuReaders.add();

    return global.IssuuReaders;
}
